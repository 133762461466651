import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 180px 0",
	"lg-padding": "90px 0 90px 0",
	"sm-padding": "45px 0 60px 0",
	"quarkly-title": "About-15"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"justify-content": "space-between",
			"lg-width": "100%",
			"lg-align-items": "stretch",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"md-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "70%",
			"lg-display": "flex",
			"lg-flex-direction": "column",
			"lg-align-items": "flex-start",
			"lg-justify-content": "flex-end",
			"md-width": "100%",
			"height": "100%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"justify-content": "space-around"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"font": "--headline2",
			"color": "--dark",
			"lg-text-align": "left",
			"md-padding": "0px 0px 0px 0px",
			"children": "Готові грати?"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"font": "--base",
			"color": "--greyD2",
			"md-text-align": "left",
			"lg-flex": "1 1 0%",
			"lg-padding": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 32px 0px",
			"sm-margin": "0px 0px 28px 0px",
			"children": "Відчуйте найкращу оренду футбольного поля в регіоні на Parmina. Забронюйте своє поле сьогодні та приєднайтеся до спільноти захоплених гравців, відданих грі."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "--lightD1",
			"text-decoration-line": "initial",
			"padding": "7px 24px 8px 24px",
			"background": "--color-primary",
			"font": "--base",
			"transition": "--opacityOut",
			"hover-opacity": ".7",
			"letter-spacing": "1px",
			"border-radius": "4px",
			"children": "Звʼязатися з нами"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"justify-content": "flex-end",
			"lg-width": "100%",
			"lg-margin": "48px 0px 0px 0px",
			"lg-flex-direction": "column"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"position": "relative"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://parminacamb.com/img/6.jpg",
			"display": "block",
			"object-fit": "cover",
			"height": "380px",
			"width": "550px",
			"lg-width": "100%",
			"lg-height": "auto",
			"lg-margin": "8px 0px 0px 0px"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="280px" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Link {...override("link")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Image {...override("image")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;